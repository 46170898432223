import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConstantService {


  ADMIN_CREDENTIALS = 'admin-credentials';

  ADMIN_INBOX = 'admin-inbox';

  SERVICE = 'Service';
  PORTFOLIO = 'Portfolio';
  EDUCATION = 'Education';
  EXPERIENCE = 'Experience';
  SKILL = 'Skill';
  ABOUT = 'About';
  CV_DATA = 'CV_Data';
  POST_DATA = 'POST_DATA';

  SETTINGS = 'Settings';
  NAV_SETTINGS = 'NavSettings'

  FRONTEND = 'Frontend';
  BACKEND = 'Backend';
  APIS = 'APIs';
  DEVOPS = 'Devops';
  MESSAGING = 'Messaging';
  DATABASE = 'Database';

  alphaNumericWithSpaceUnderScore = '^[a-zA-Z0-9]+([ _][a-zA-Z0-9]+)*$';
  emailPattern = '^\\w+([\.!#\$%&\'\*\+-=\?\^`\{\|"\(\),:;<>\[\\\]]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$';
  contactPattern = "^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$";
}
