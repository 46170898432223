import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DataService } from '../common/data.service';
import { ConstantService } from '../common/constant.service';
import { Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-post-view',
  templateUrl: './post-view.component.html',
  styleUrls: ['./post-view.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class PostViewComponent implements OnInit {

  isPostView: boolean = true;

  @Input() postDetails: any = null;
  @Input() imageData: any = null;
  tags = [];

  likes: number = 0;
  views: number = 0;

  isLiked = false;

  commentMessage = '';
  commentName = ''

  constructor(public sanitized: DomSanitizer, private dataService: DataService, private constant: ConstantService, private router: Router, private meta: Meta,
    private title: Title) {

  }

  ngOnInit() {
    this.tags = this.postDetails.tags;
    if (sessionStorage.getItem(this.postDetails.key) == 'Y') {
      this.isLiked = true;
    }
    //this.increaseViewCount(this.postDetails);
    this.refereshPost();
    this.commentMessage = '';
    this.commentName = '';
    this.generateMetaData();
  }


  incrementLikes(): void {
    if (sessionStorage.getItem(this.postDetails.key) != 'Y') {
      sessionStorage.setItem(this.postDetails.key, 'Y');
      this.isLiked = true;
      this.changeLikeCount(this.postDetails, true);
    }
  }

  decrementLikes(): void {
    if (sessionStorage.getItem(this.postDetails.key) == 'Y') {
      sessionStorage.setItem(this.postDetails.key, 'N');
      this.isLiked = false;
      this.changeLikeCount(this.postDetails, false);
    }
  }

  incrementViews(): void {
    this.views++;
  }


  changeLikeCount(post, isIncrement: boolean = true) {
    let like = isIncrement ? post.likes + 1 : post.likes - 1;
    const postRef = this.dataService.dataRef(this.constant.POST_DATA, post.key).ref;
    postRef.update({ likes: like });
    post.likes = like;
  }


  refereshPost() {
    let key = this.postDetails.key;
    let isRefersh = true;
    const postRef = this.dataService.dataRef(this.constant.POST_DATA, key).ref;
    postRef.onSnapshot(snapshot => {
      if (snapshot.docs) {
        snapshot.docs.forEach(doc => {
          if (doc.data() && isRefersh) {
            //isRefersh = false;
            this.postDetails = doc.data();
            // this.increaseViewCount(this.postDetails);
          }
        })
      }
    });
  }

  isFilled() {
    if (this.commentName == '' || this.commentMessage == '') {
      return true
    }

  }

  addComment(postDetails, name, message) {
    let newMsg = {
      name: name,
      dateTime: new Date(),
      message: message,
      isHidden: false
    }

    this.dataService.addUpdateToArray(postDetails.key, this.constant.POST_DATA, newMsg);
    postDetails.comments.push(newMsg);
    this.commentMessage = '';
    this.commentName = '';
  }

  // Example data


  shareOnLinkedIn(post) {
    let urlToShare = 'https://blogs.dreaminnovator.in/blog/' + post.key;
    let title = post.title;
    let summary = post.summary;
    let source = 'Dream Innovator Blog';
    const linkedInUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      urlToShare
    )}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(
      summary
    )}&source=${encodeURIComponent(source)}`;
    window.open(linkedInUrl, '_blank');
  }

  generateMetaData() {
      this.title.setTitle('Dream Innovator | ' + this.postDetails.title);
      this.meta.addTags([
        { property: 'og:title', content: this.postDetails.title },
        { property: 'og:description', content: this.postDetails.summary },
        { property: 'og:image', content: this.postDetails.banner },
        { property: 'og:url', content: 'https://blogs.dreaminnovator.in/blog/' + this.postDetails.key }
      ]);
    }
  
}
