import { Component, OnInit, Renderer2 } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common/common.service';
import { ConstantService } from '../common/constant.service';
import { DataService } from '../common/data.service';
import { NotificationService } from '../common/notification.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import * as $ from 'jquery';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {

  postList = [];
  temPostList = [];
  styleSheet: any;

  isPreview = false
  previewPostData: any = null;

  tagList = [];

  blogId = '';
  isOpenedById = false;

  isSearch = false;

  searchTitle = '';
  searchSummary = '';
  searchTag = [];
  searchTagPage = [];

  showMoreCount = 1;
  isShowMoreButton = false;

  isTagSearch = false;
  isSeachBox = false;

  isSearched = false;

  constructor(private dataService: DataService, private notification: NotificationService, private spinner: NgxSpinnerService,
    public constant: ConstantService, public commonService: CommonService, private renderer: Renderer2, public sanitized: DomSanitizer, private router: Router, private location: Location) {
    this.styleSheet = document.createElement('style');
    this.styleSheet.type = 'text/css';
    this.commonService.getSearchSub().subscribe(message => {
      if (message && message.search) {
        this.isSearch = true;
      }
    });
  }

  ngOnInit() {
    this.commonService.logEvents("Blogs");
    setTimeout(() => {
      let doc = document.getElementsByClassName("pg-canvas")[0];
      if (!this.commonService.isEmptyObject(doc)) {
        doc.setAttribute('style', 'display: none');
      }
    }, 1000);

    this.isOpenedById = false;
    this.blogId = this.router.url.split('/')[2];
    this.postList = [];
    this.getBlogCreatorList();
  }



  previewPost(post) {
    this.isPreview = true;
    this.previewPostData = post;
    this.changeUrl(this.previewPostData.key);
    this.increaseViewCount(post);
  }

  getBlogCreatorList() {
    this.spinner.show();
    const serviceRef = this.dataService.dataColRef(this.constant.POST_DATA).ref;
    this.tagList = [];
    let fetch = true;
    serviceRef.onSnapshot(snapshot => {

      snapshot.docs.forEach(doc => {
        if (doc.data() && fetch) {
          this.postList.push(
            {
              key: doc.id,
              sequence: doc.data().sequence,
              title: doc.data().title,
              summary: doc.data().summary,
              banner: doc.data().banner,
              detail: doc.data().detail,
              gridColor: doc.data().gridColor,
              textColor: doc.data().textColor,
              views: doc.data().views,
              likes: doc.data().likes,
              tags: doc.data().tags,
              isArchived: doc.data().isArchived,
              author: doc.data().author,
              authorLink: doc.data().authorLink,
              comments: doc.data().comments,
            });
          this.tagList.push(...doc.data().tags);
        }
      });
      if (fetch) {
        this.sortList(this.postList);
        this.postList = this.postList.filter(post => !post.isArchived);
        this.tagList = [...new Set(this.tagList)];
        this.temPostList = JSON.parse(JSON.stringify(this.postList));
        this.generateDynamicCSS();
        if (this.postList.length > 20) {
          this.postList = this.postList.slice(0, this.showMoreCount * 20);
          this.isShowMoreButton = true;
        } else {
          this.isShowMoreButton = false;
        }
      }
      if (this.blogId) {
        let blog = this.postList.filter(post => post.key == this.blogId);
        if (blog.length > 0) {
          this.previewPost(blog[0]);
          this.blogId = '';
          this.isOpenedById = true;
        } else {
          this.blogId = '';
          this.location.go('/blog');
        }
      }
      fetch = false;
      this.spinner.hide();
    });
   
  }


  showMore() {
    this.showMoreCount++;
    if (this.isSeachBox) {
      this.searchBlogsContent();
    } else if (this.isTagSearch) {
      this.selectTag('');
    } else {
      let temp = JSON.parse(JSON.stringify(this.temPostList));
      this.postList = temp.slice(0, this.showMoreCount * 20);
      this.generateDynamicCSS();
    }
    if (this.temPostList.length < this.showMoreCount * 20) {
      this.isShowMoreButton = false;
    }
  }

  searchBlogsContent() {
    this.isSearched = true;
    this.postList = [];
    this.showMoreCount = 1;
    let tempPost = JSON.parse(JSON.stringify(this.temPostList));
    let isFilter = false;

    if (this.searchTitle != '') {
      this.postList.push(...tempPost.filter(post => post.title.toLowerCase().includes(this.searchTitle.toLowerCase())));
      isFilter = true;
    }

    if (this.searchSummary != '') {
      this.postList.push(...tempPost.filter(post => post.summary.toLowerCase().includes(this.searchSummary.toLowerCase())));
      isFilter = true;
    }

    if (this.searchTag.length) {
      for (let tg of this.searchTag) {
        this.postList.push(...tempPost.filter(post => post.tags.includes(tg)));
        this.searchTagPage = this.searchTag;
      }
      isFilter = true
    } else {
      this.searchTagPage = [];
    }

    if (!isFilter) {
      this.postList = tempPost;
    }

    this.postList = this.postList.reduce((a, b) => {
      if (!a.find(data => data.key === b.key)) {
        a.push(b);
      }
      return a;
    }, []);

    if (this.postList.length > 20) {
      this.postList = this.postList.slice(0, this.showMoreCount * 20);
      this.isShowMoreButton = true;
    } else {
      this.isShowMoreButton = false;
    }
    this.generateDynamicCSS();
    this.isSearch = false;

  }

  cancelSearch(){
    if(!this.isSearched){
      this.searchTag = [];
      this.searchSummary = '';
      this.searchTitle = '';
    }
    this.isSearch = false
  }


  selectTag(tag) {
    this.showMoreCount = 1;
    if (this.searchTagPage.includes(tag)) {
      this.searchTagPage = this.searchTagPage.filter(tg => tg != tag);
    } else if (tag) {
      this.searchTagPage.push(tag);
      this.searchTagPage = [...new Set(this.searchTagPage)];
    }

    if (this.searchTagPage.length) {
      this.filterByTag(this.searchTagPage);

    } else {
      this.postList = JSON.parse(JSON.stringify(this.temPostList));
      if (this.postList.length > 20) {
        this.postList = this.postList.slice(0, this.showMoreCount * 20);
        this.isShowMoreButton = true;
      } else {
        this.isShowMoreButton = false;
      }
      this.searchTag = [];
      this.generateDynamicCSS();
    }

  }

  searchTagByDialog(tag) {
    if (this.searchTag.includes(tag)) {
      this.searchTag = this.searchTag.filter(tg => tg != tag);
    } else {
      this.searchTag.push(tag);
      this.searchTag = [...new Set(this.searchTag)];
    }
  }


  filterByTag(tagList) {
    this.postList = [];
    let tempPost = JSON.parse(JSON.stringify(this.temPostList));
    for (let tg of tagList) {
      this.postList.push(...tempPost.filter(post => post.tags.includes(tg)));
    }
    this.postList = this.postList.reduce((a, b) => {
      if (!a.find(data => data.key === b.key)) {
        a.push(b);
      }
      return a;
    }, []);

    if (this.postList.length > 20) {
      this.postList = this.postList.slice(0, this.showMoreCount * 20);
      this.isShowMoreButton = true;
    } else {
      this.isShowMoreButton = false;
    }
    this.generateDynamicCSS();

  }


  sortList(objs) {
    objs.sort((a, b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0));
  }

  generateDynamicCSS() {
    this.sortList(this.postList);
    let css = "";
    let postCount = this.postList.length;
    for (var i = 0; i < postCount; i++) {
      let dynamicStyles = ".tile:nth-child(" + i + ") { animation-delay: 0." + i + "s; }";
      css = css + ' \n ' + dynamicStyles;
      document.head.appendChild(this.styleSheet);
      let d = ".background-view-" + i + "{background:linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('" + this.postList[i].banner + "');}"
      css = css + ' \n ' + d;
      let txt = ".ban-text-" + i + "{ color:" + this.postList[i].textColor + "}"
      css = css + ' \n ' + txt;
    }
    this.styleSheet.innerText = css;
    document.head.appendChild(this.styleSheet);

  }

  increaseViewCount(post) {
    let view = post.views + 1;
    const postRef = this.dataService.dataRef(this.constant.POST_DATA, post.key).ref;
    postRef.update({ views: view });
    post.views = view;
  }

  changeUrl(postKey: string): void {
    if (postKey) {
      this.location.go('/blog/' + postKey);
    } else {
      if (this.isOpenedById) {
        this.location.go('/blog');
        this.isOpenedById = false;
      } else {
        this.location.back();
      }
    }
  }

}
