import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostViewComponent } from '../post-view/post-view.component';
import { FormsModule } from '@angular/forms';


@NgModule({
imports: [
    CommonModule,
    FormsModule
],
declarations: [
   PostViewComponent
],
exports: [
    PostViewComponent
],
schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule {}