import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConstantService } from './constant.service';
import * as firebase from 'firebase/app';

@Injectable({
  providedIn: 'root'
})
export class DataService {


  constructor(public db: AngularFirestore, private dbStore: AngularFireStorage,
    private http: HttpClient, private constantService: ConstantService) { }


  getLoginRef() {
    const loginRef: AngularFirestoreDocument<any> = this.db.collection(this.constantService.ADMIN_CREDENTIALS).doc(this.constantService.ADMIN_CREDENTIALS);
    return loginRef;
  }

  contactListRef() {
    const contactRef: AngularFirestoreDocument<any> = this.db.collection(this.constantService.ADMIN_INBOX).doc(new Date().getTime().toString());
    return contactRef;
  }

  inboxRef() {
    const contactRef: AngularFirestoreCollection<any> = this.db.collection(this.constantService.ADMIN_INBOX);
    return contactRef;
  }

  dataRef(location, key) {
    const ref: AngularFirestoreDocument<any> = this.db.collection(location).doc(key);
    return ref;
  }

  dataColRef(location) {
    const ref: AngularFirestoreCollection<any> = this.db.collection(location);
    return ref;
  }

  skillRef(skill, skillKey) {
    const ref: AngularFirestoreDocument<any> = this.db.collection(this.constantService.SKILL).doc(skill)
      .collection(skill).doc(skillKey);
    return ref;
  }

  skillColRef(skill) {
    const ref: AngularFirestoreCollection<any> = this.db.collection(this.constantService.SKILL).doc(skill)
      .collection(skill);
    return ref;
  }

  addUpdateToArray(docId: string, collection: string, newItem: any) {
    return this.db
      .collection(collection)
      .doc(docId)
      .update({
        comments: firebase.firestore.FieldValue.arrayUnion(newItem)
      });
  }

  searchByPrefix(searchTerm: string) {
    // const itemsCollection = this.db.collection('items');
    // // itemsCollection.
    
    // // // Firestore query for prefix search (>= and < for lexicographical range)
    // // const snapshot =  itemsCollection.where('name', '>=', searchTerm).where('name', '<', searchTerm + '\uf8ff') // \uf8ff is the last Unicode character
    // //   .get();
  
    // // Return matching documents
    // return snapshot.docs.map(doc => doc.data());
  }

}
