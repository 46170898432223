import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { CommonService } from '../common/common.service';
import { ConstantService } from '../common/constant.service';
import { DataService } from '../common/data.service';
import { CacheService } from '../common/cache-data.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  projects = [];
  showPortfolio: any = [];

  fallBackImage = 'assets/img/soft_process.png';

  assetMap = new Map();

  constructor(private dataService: DataService, private constant: ConstantService, private commonService: CommonService,
    private spinner: NgxSpinnerService, private cacheService: CacheService) { }

  ngOnInit(): void {
    this.commonService.logEvents("PortFolio");
    let doc = document.getElementsByClassName("pg-canvas")[0];
    if (!this.commonService.isEmptyObject(doc)) {
      doc.setAttribute('style', 'opacity: 0.2; position: fixed !important; height: 100%;');
    }

    this.getPortfolioList();

  }

  getPortfolioList() {
    this.spinner.show()
    const portfolioRef = this.dataService.dataColRef(this.constant.PORTFOLIO).ref;
    portfolioRef.onSnapshot(snapshot => {
      this.projects = [];
      snapshot.docs.forEach(doc => {
        if (doc.data()) {
          this.projects.push({ key: doc.id, sequence: doc.data().sequence, imageUrl: doc.data().imageUrl, name: doc.data().name, description: doc.data().description, url: doc.data().url });
          this.loadAsset(doc.id, doc.data().imageUrl);
        }
      });
      this.commonService.sortList(this.projects);
      this.renderPortfolio();
      this.spinner.hide()
    });
  }

  renderPortfolio() {
    let y = 0
    let row = [];
    let col = [];
    for (let proj of this.projects) {
      col.push(proj);
      y++;
      if (y == 3) {
        row.push(col);
        col = [];
        y = 0;
      }
    }

    let size = 3 - this.projects.length % 3;
    if (size != 3) {
      for (let i = 0; i < size; i++) {
        col.push([]);
      }
      row.push(col);
    }

    this.showPortfolio = row;
    localStorage.setItem(this.constant.PORTFOLIO, JSON.stringify(row));
  }


  isUrl(url) {
    var re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (!re.test(url)) {
      return false;
    }
    return true;
  }

  async loadAsset(key, assetUrl) {
    try {
      const blob = await this.cacheService.fetchOrCache(assetUrl);
      this.assetMap.set(key, URL.createObjectURL(blob)) // Create a local URL for the asset
    } catch (error) {
      console.error('Error loading asset:', error);
    }
  }

}
