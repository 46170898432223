import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class RouterConfigService {

  public static routerMap = {
    'login': 'login',
    'homepage': 'homepage',
    'about': 'about',
    'contact': 'contact',
    'portfolio': 'portfolio',
    'services': 'services',
    'inbox': 'inbox',
    'maintenance': 'maintenance',
    'blog':'blog'
  };

  constructor() { }



}
