import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class CacheService {

  private cacheName = 'asset-cache';

  async fetchOrCache(url: string): Promise<Blob> {
    const cache = await caches.open(this.cacheName);

    // Check if the asset is already in the cache
    const cachedResponse = await cache.match(url);
    if (cachedResponse) {
      console.log('Asset fetched from cache');
      return cachedResponse.blob();
    }

    // Fetch from the server and store in cache
    const response = await fetch(url, {  mode: 'cors' });
    if (response.ok) {
      console.log('Asset fetched from server and cached');
      await cache.put(url, response.clone());
      return response.blob();
    } else {
      throw new Error(`Failed to fetch asset: ${response.statusText}`);
    }
  }
}