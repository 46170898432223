import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  analytics = null;

  private searchSub: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor() {
    this.analytics = firebase.analytics();
  }


  getSearchSub() {
    return this.searchSub.asObservable();
  }

  setSearchSub(value: any) {
    this.searchSub.next(value);
  }

  sortList(objs) {
    objs.sort((a, b) => (a.sequence > b.sequence) ? 1 : ((b.sequence > a.sequence) ? -1 : 0));
  }


  isEmptyObject(obj: any) {
    if (obj === undefined || obj === null || obj.length === 0) {
      return true;
    }
    return false;
  }


  logEvents(eventName: String): void {
    this.analytics.logEvent(eventName);
  }

}
